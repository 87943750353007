<template>
  <div style="margin: -15px -15px; background-color: #f0f2f5">
    <!-- tag查询框 -->
    <div class="datePicker">
      功能类型：<a-radio-group  v-model="Functiontype" style="margin-right: 40px" @change="searchOk">
        <a-radio-button value="h5">
          H5
        </a-radio-button>
        <a-radio-button value="app">
          APP原生开发
        </a-radio-button>
        <a-radio-button value="mina">
          小程序原生开发
        </a-radio-button>
      </a-radio-group>
    </div>
    <div class="table">
      <a-button type="primary" style="margin-bottom: 15px;" @click="newlyBuilt">
        <a-icon type="plus" />
         新建
      </a-button>
      <a-table
          :columns="columns"
          style="min-height: 500px; background-color: #fff"
          :data-source="HeplTable"
          :rowKey="(record) => record.id"
          :pagination="false"
        >
        <a slot="action" slot-scope="text,item">
          <a @click="editInfo(item)" style="margin-right: 8px">编辑</a>
          <a-popconfirm title="确认删除吗？" ok-text="是" cancel-text="否" @confirm="delInfo(item)">  
            <a style="margin-right: 8px">删除</a>
          </a-popconfirm>
        </a>
      </a-table>
      <!-- table框的分页 -->
      <div
        style="
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
          <span
            style="
              font-size: medium;
              color: #929292;
              margin-left: 3%;
              font-size: 14px;
              bottom: 3%;
            "
          >
            共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
            {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
          </span>
          <a-pagination
            style="float: right"
            show-quick-jumper
            show-size-changer
            :page-size.sync="pagination.pageSize"
            :total="pagination.total"
            v-model="pagination.current"
            @change="pageChange"
            @showSizeChange="pageChange"
          />
      </div>
    </div>
    <!-- 新建抽屉 -->
    <a-drawer title="新增/编辑" placement="right" :closable="true" :visible="newlyVisible"
     @close="onClose" width="42%">
     <a-form-model :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="form">
       <a-form-model-item label="功能名称"  prop="funcName">
         <a-input placeholder="请输入" v-model="addForm.funcName">
         </a-input>
       </a-form-model-item>
       <a-form-model-item label="功能类型" prop="funcType">
         <a-radio-group v-model="addForm.funcType" @change="radio_group_onChange">
           <a-radio value="h5">
             H5
           </a-radio>
           <a-radio value="app">
             APP原生开发
           </a-radio>
           <a-radio value="mina">
             小程序原生开发
           </a-radio>
         </a-radio-group>
       </a-form-model-item>
       <a-form-model-item label="功能链接/Code值"  prop="funcLinkCode">
         <a-input placeholder="请输入" v-model="addForm.funcLinkCode">
         </a-input>
       </a-form-model-item>
     </a-form-model>            
     <div
       :style="{
         position: 'absolute',
         right: 0,
         bottom: 0,
         width: '100%',
         borderTop: '1px solid #e9e9e9',
         padding: '10px 16px',
         background: '#fff',
         textAlign: 'right',
         zIndex: 1,
       }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="()=>{
          this.newlyVisible = false;
          this.$refs.form.resetFields()
          this.addForm = {}
        }">
          取消
        </a-button>
        <!-- 提交并更新简介 -->
        <a-button :style="{ marginRight: '8px' }" type="primary" @click="submit" v-if="buttonType === 'new'" >
          确认
        </a-button>
        <a-button :style="{ marginRight: '8px' }" type="primary" @click="editData" v-if="buttonType === 'edit'">
          确定
        </a-button>
     </div>
    </a-drawer>
  </div>
</template>

<script>
import {
  getFunctionModule,postFunctionModule,putFunctionModule,delFunctionModule
} from "../../../service/HomeFunctionManagement";
export default {
  data(){
    return{
      id:'',
      buttonType:'new',
      rules: {
        funcName: [{ required: true, message: '请输入', trigger: 'blur' }],
        funcType: [{ required: true, message: '请选择', trigger: 'change' }],
        funcLinkCode: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      labelCol: { span: 6},
      wrapperCol: { span: 18 },
      addForm:{},
      newlyVisible:false,
      Functiontype:'h5',
      columns: [
          {
            title: "功能名称",
            dataIndex: "funcName",
            key: "funcName",
            align:'center',
          },
          {
            title: "功能类型",
            dataIndex: "funcType",
            key: "funcType",
            align:'center',
            // scopedSlots: { customRender: "source" },
          },
          {
            title: "功能链接/Code值",
            dataIndex: "funcLinkCode",
            key: "funcLinkCode",
            align:'center',
          },
          {
            title: "创建人",
            dataIndex: "createdByName",
            key: "createdByName",
            align:'center',
          },
          {
            title: "创建时间",
            dataIndex: "createdTime",
            key: "createdTime",
            align:'center',
          },
          {
            title: "操作",
            fixed:'right',
            width: 150,
            align:'center',
            scopedSlots: { customRender: 'action' },
          },
      ],
      HeplTable: [],
      pagination: {
          //存储大table的分页
          total: 0,
          current: 1,
          pageSize: 10,
          pageNum: 1,
        },
    }
  },
  created() {
    this.getTable();
  },
  methods:{
    searchOk(){
     this.getTable()
    },
    // 新建
    newlyBuilt(){
      this.newlyVisible = true
      this.buttonType = 'new'
    },
    // 获取列表
    async getTable() {
      let data = {
        funcType:this.Functiontype,
        pageNo:this.pagination.pageNum,
        pageSize:this.pagination.pageSize
      }
      const response = await getFunctionModule(data)
      if (response.code === 0) {
        this.HeplTable = response.data;
        this.pagination.total = response.count;
      } else {
        this.$message.warning(response.message);
      }
    },
    //  关闭新建抽屉
    onClose(){
      this.newlyVisible = false
      this.$refs.form.resetFields()
      this.addForm = {}
    },
    radio_group_onChange(e){
    //   this.live_page_no=1
    //   this.doctor_page_no=1
    //   this.LiveConferenceList = []
    //   this.addForm.type = e.target.value
    //   if(e.target.value === 1){
    //     this.Live_Conference_List()
    //   }if(e.target.value === 2){
    //     this.getCaseLiveList()
    //   }
    // //  清空选中的会议id
    //   this.liveId = ''
    //   this.addForm.live = undefined
    //   this.liveRoom = [undefined]
    //   this.liveRoomList = []
    },
    // 新建后的提交按钮
    async submit() {
      this.$refs.form.validate(async valid => { // 表单校验
        if (valid) {
          const data = {
            funcName: this.addForm.funcName, 
            funcType: this.addForm.funcType, 
            funcLinkCode: this.addForm.funcLinkCode,  
          }
          const response = await postFunctionModule(data)
          if (response.code === 0) {
            this.getTable()
            this.$refs.form.resetFields()
            this.addForm = {}
            this.newlyVisible = false;
            this.$message.success('新增成功')
          }
        } else { 
           this.$message.error("请填写完整")
        }
       })
    },
    // 提交编辑
    async editData() {
      this.$refs.form.validate(async valid => { // 表单校验
        if (valid) {
          const data = {
            id:this.id,
            funcName: this.addForm.funcName, 
            funcType: this.addForm.funcType, 
            funcLinkCode: this.addForm.funcLinkCode,  
          }
          const response = await putFunctionModule(data)
          if (response.code === 0) {
            this.getTable()
            this.$refs.form.resetFields()
            this.addForm = {}
            this.newlyVisible = false;
            this.$message.success('修改成功')
          }
        } else { 
           this.$message.error("请填写完整")
        }
       })

    },
    // 编辑
    editInfo(record){
      this.newlyVisible = true
      this.buttonType = 'edit'
      this.id = record.id
      this.addForm = JSON.parse(JSON.stringify(record))  //content和备注
    },
    // 删除
    async delInfo(record){
      const response = await delFunctionModule(record.id)
      if (response.code === 0) {
        this.$message.success("删除成功");
        this.getTable()
      } else {
        this.$message.warning(response.message);
      }
    },
    // 分页
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no;
      this.pagination.pageSize = page_size;
      this.getTable();
    },
  }
}
</script>

<style lang="scss" scoped>
.datePicker {
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}
.table{
  background-color: #fff;
  padding:15px 20px
}
::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}
</style>